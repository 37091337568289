@import '@cian/ui-kit/typography/variables.module.css';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  text-decoration: unset;
  border: 1px solid #c9d1e5;
  border-radius: 12px;
}

.gallery {
  flex: 1;
}

.gallery img {
  border-radius: 12px 12px 0 0;
  aspect-ratio: 320 / 280;
}

.info {
  margin: 8px 12px 12px;
  display: flex;
  flex-direction: column;
}

.address {
  overflow: hidden;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--black_60);

  &.two-rows {
    /* stylelint-disable value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* stylelint-enable value-no-vendor-prefix */
    line-height: var(--fontSize_22px);
    white-space: initial;
    text-overflow: initial;
  }
}

.description {
  overflow: hidden;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  color: var(--decorative-theme-dark);
}

.favorite {
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 12px;
}
